<template lang="pug">
.table-responsive
  table.table.align-items-center
    thead
      tr
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Deal
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Status
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Closed At
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Actions

    tbody
      DealItem(v-for='deal in data' :deal='deal' :key='deal.id' @remove="removeItem" @archive="removeItem" @unarchive="removeItem")

.observer(v-intersection="fetchAdditionalData")
Spinner(v-if="loading")
</template>

<script>
import DealItem from './DealItem.vue'
import Spinner from '../Spinner.vue'
import loadData from '../../hooks/loadData'
import dataHelpers from '../../mixins/dataHelpers'

export default {
  data() {
    return {
    }
  },
  components: { DealItem, Spinner },
  props: {
    api_endpoint: {
      type: String,
      required: true
    }
  },
  mixins: [dataHelpers],
  mounted() {
    this.fetchData()
  },
  setup(props) {
    const { fetchData, fetchAdditionalData, data, loading, currentPage, totalPages } = loadData(props.api_endpoint, 25)
    return {
      data,
      fetchData,
      fetchAdditionalData,
      loading,
      currentPage,
      totalPages
    }
  }
}
</script>
