import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ["flatpickrDate", "flatpickrDatetime"]

  flatpickrDateTargetConnected(element) {
      flatpickr(element, {dateFormat: "m/d/Y", allowInput: true,})
  }

  flatpickrDatetimeTargetConnected(element) {
    flatpickr(element, {
      allowInput: true,
      enableTime: true,
      dateFormat: "m/d/Y G:i K",
      time_24hr: false
    })
  }

  // flatpickrTargetDisconnected(selectInput) {
  // }
}
