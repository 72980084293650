<template lang="pug">
tr
  td
    div(class="d-flex align-items-center")
      div.img-section(v-if="vehicle.image_url" class="d-flex align-items-center")
        img(class="inventory-image ms-3" :src="vehicle.image_url")
        div.img-counter {{ vehicle.images_count }}
      i(class="fas fa-images no-inventory-image ms-3" v-else)
      a(:href="'/inventory/' + vehicle.id + '/edit'" class="d-flex align-items-center ms-3")
        h6(class="ms-3 my-auto d-flex align-items-center")
          i(class="fas fa-user text-secondary me-3")
          .word-wrapper(style="flex: 1; display: flex; align-items: center;") 
            | {{ vehicle.simple_name }}
          .tags-wrapper(style="display: flex; align-items: center;")
            span(class="badge bg-gradient-primary mx-3" v-for="tag_name in vehicle.tag_list") {{ tag_name }}

  td(class="text-sm") {{ vehicle.vin }}
  td(class="text-sm") {{ vehicle.price }}
  td(class="text-sm text-center") {{ vehicle.stock_number }}

  td(class="text-sm text-center")
    a(href="javascript:;" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Delete" @click.stop.prevent="remove")
      i(class="fas fa-trash text-secondary" title="Delete")

    a(v-if="vehicle.archived" href="#" data-bs-toggle="tooltip" data-bs-original-title="Unarchive" @click.stop.prevent="unarchive")
      i(class="fas fa-undo text-secondary" title="Unarchive")

    a(v-else href="#" data-bs-toggle="tooltip" data-bs-original-title="Archive" @click.stop.prevent="archive")
      i(class="fas fa-arrow-down text-secondary" title="Archive")

</template>

<script>

import archiveData from '../../hooks/archiveDeleteData'

export default {
  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const {archive, unarchive, remove} = archiveData('/api/inventory/', props.vehicle.id, emit)

    return { archive, unarchive, remove }
  }
}
</script>

<style lang="sass" scoped>
  .inventory-image
    width: 60px
    border-radius: 5px

  .no-inventory-image
    padding: 11px 0 10px 11px
    margin-right: 0.6rem
    font-size: 35px

  .img-section
    position: relative

    .img-counter
      position: absolute
      bottom: 0
      right: 0
      color: white
      font-weight: bold
      background: gray
      font-size: 14px
      opacity: 0.8
      border-radius: 5px 0 0 0
      padding: 0 1px 0
  
  .word-wrapper
    display: block
    white-space: normal
    word-break: break-word
    max-width: 200px
    text-align: left
    display: flex
    align-items: center

  .tags-wrapper
    display: flex
    align-items: center
</style>
