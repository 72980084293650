document.addEventListener('turbo:load', () => {
  $("#add_feature_button").unbind("click");
  $('a.accordion-toggle').unbind("click");
  $(document).ready(function() {
    activate_vin_lookup();
    $('.applyBtn').on('click', function() {
      var dateRange = $('#dateRangeInput').val();
      var selectedNavValued =  $('.nav-link.selected').attr('data-value') || 'd';
      var dateArray = dateRange.split(' - ');
      var startDate = dateArray[0];
      var endDate = dateArray[1];
      var url = '/overview?start_date=' + startDate + '&end_date=' + endDate + '&nav_stat=' + selectedNavValued;
      Turbo.visit(url, { action: 'replace', frame: "overview-frame" });
    });
    var startDate, endDate;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const startDateParam = urlSearchParams.get("start_date");
    const endDateParam = urlSearchParams.get("end_date");
    if (startDateParam && endDateParam){
      $('#dateRangeInput').val(startDateParam + ' - ' + endDateParam);
      var parentElement = document.getElementsByClassName('choices__list--single')[1];
      var elementToHide = parentElement.getElementsByClassName('choices__item--selectable')[0];
      if (elementToHide) {
        elementToHide.style.display = 'none';
      }
      endDate = new Date(endDateParam)
      startDate = new Date(startDateParam)
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    if ($('#dailySelect').val() === 'week') {
      if (!startDateParam){
        endDate = new Date();
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 6);
        startDate = formatDate(startDate);
        endDate = formatDate(endDate);
        $('#dateRangeInput').val(startDate + ' - ' + endDate);
      }
    }
    else if($('#dailySelect').val() === 'mon'){
      endDate = new Date();
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 29);
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    else if ($('#dailySelect').val() === '21d'){
      endDate = new Date();
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 21);
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    else if($('#dailySelect').val() === 'mtd'){
      endDate = new Date();
      startDate = new Date();
      startDate.setDate(1);
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    else if($('#dailySelect').val() === 'qtd'){
      endDate = new Date();
      startDate = getStartOfQuarter(new Date());
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    else if($('#dailySelect').val() === 'ytd'){
      endDate = new Date();
      var startDate = getStartOfYear(new Date());
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    var selectedNavValued =  $('.nav-link.selected').attr('data-value') || 'd';
    $('#dailySelect').on('change', function() {
      selectedSelectValue = $(this).val();
      var url = '/overview?graph_stat=' + selectedSelectValue + '&nav_stat=' + selectedNavValued;
      Turbo.visit(url, { action: 'replace', frame: "overview-frame" });
    });
    $('.nav-link.active').data('value', selectedSelectValue);

    var selectedSelectValue = $('#dailySelect').val();
    var selectedNavValue = "d";
    $('.nav-link[data-value="' + selectedNavValue + '"]').addClass('active'); // Add 'active' class to the default nav link

    $('.navLink').on('click', function(e) {
      e.preventDefault();
      $('.nav-link').removeClass('active'); // Remove 'active' class from all nav links
      $(this).addClass('active'); // Add 'active' class to the clicked nav link
      selectedNavValue = $(this).data('value');
      const urlSearchParams = new URLSearchParams(window.location.search);
      const startDateParam = urlSearchParams.get("start_date");
      const endDateParam = urlSearchParams.get("end_date");
      if (startDateParam && endDateParam){
        $('#dateRangeInput').val(startDateParam + ' - ' + endDateParam);
        var url = '/overview?start_date=' + startDateParam + '&end_date=' + endDateParam + '&nav_stat=' + selectedNavValue;
      }
      else {
        var url = '/overview?graph_stat=' + selectedSelectValue + '&nav_stat=' + selectedNavValue;
      }
      Turbo.visit(url, { action: 'replace', frame: "overview-frame" });
    });

    // Update select menu when nav link is clicked
    $('#dailySelect').val(selectedNavValue);

    $('#collapse-feature').css('display', 'block');

    $('.clear_features').on('click', function (event) {
      var section, name, $el;

      event.preventDefault();

      section = $(this).data('feature-section');
      name = 'inventory[features][' + section + '][]';
      $el = $('#' + section + '_features');
      $el.empty();
      if ($('input[name="' + name + '"]').length === 0) $el.after('<input type="hidden" name="' + name + '">');
    });

    const form = document.querySelector('form');
    const yesCheckbox = document.getElementById('header_row_yes');
    const noCheckbox = document.getElementById('header_row_no');

    form.addEventListener('submit', function(event) {
      if (!yesCheckbox.checked && !noCheckbox.checked) {
        alert('Please select at least one option (Yes or No).');
        event.preventDefault();
      }
    });
    var links = document.querySelectorAll('.add-vendor');
    var forms = document.querySelectorAll('.inner-form-vendor');
    forms.forEach(function(form) {
        form.style.display = 'none'; // Hide all forms initially
    });

    links.forEach(function(link, index) {
        link.addEventListener('click', function(e) {
            e.preventDefault(); // Prevent default link behavior
            var forms = document.querySelectorAll('.inner-form-vendor');
            var formToShow = forms[index];
            var isFormDisplayed = formToShow.style.display === 'block';

            forms.forEach(function(form, i) {
                form.style.display = (i === index && !isFormDisplayed) ? 'block' : 'none';
            });

            // Update link text based on form display status
            if (isFormDisplayed) {
                link.textContent = 'Add new vendor';
                link.style.color = '#5e72e4';
            } else {
                link.textContent = 'Remove New Vendor';
                link.style.color = 'red';
            }
        });
    });

    const checkboxes = document.querySelectorAll('.form-check-input');

    checkboxes.forEach(function(checkbox) {
      checkbox.addEventListener('change', function() {
        if (this.checked) {
          // Uncheck the other checkbox
          if (this.id === 'header_row_yes') {
            noCheckbox.checked = false;
          } else if (this.id === 'header_row_no') {
            yesCheckbox.checked = false;
          }
        }
      });
    });
    $('#remind-at').hide();
    $('#reminder-checkbox').change(function() {
      if ($(this).is(':checked')) {
        $('#remind-at').show();
      } else {
        $('#remind-at').hide();
      }
    });
  });
  $('a.accordion-toggle').on('click', function(e) {
    e.preventDefault();
    $('#collapse-feature').toggle();
  });

  $('#custom_link_field').hide();

  $('#add_custom_link').click(function(event){
    event.preventDefault();
    $('#custom_link_field').show();
    $('#add_custom_link').hide();
  });

  var accordionToggles = document.querySelectorAll('.accordion-toggle');

  accordionToggles.forEach(function (toggle) {
  toggle.addEventListener('click', function (event) {
    event.preventDefault();

    var accordionBodyId = this.getAttribute('href').substring(1);
    var accordionBody = document.getElementById(accordionBodyId);

    // Toggle the 'hidden' class to show/hide the accordion body
    if (accordionBody.style.display === 'none' || accordionBody.style.display === '') {
      accordionBody.style.display = 'block';
    } else {
      accordionBody.style.display = 'none';
    }
    });
  });
  $("input#add_feature").keypress(function(e){
    // 13 = enter key
    if (e.which == 13) { e.preventDefault(); add_feature_via_form() }
  });
  $("#add_feature_button").click(function(e){
    e.preventDefault();
    add_feature_via_form();
    $("#add_feature").focus();
  });
  function add_feature_via_form(){
    var feature_field = $("#add_feature");
    var value = $("#add_feature").val();
    feature_field.val("");
    add_feature_to_inventory(value, "standard")
  }
  function random_string(length) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');
    if (! length) {
        length = Math.floor(Math.random() * chars.length);
    }
    var str = '';
    for (var i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
    }
    return "item_" + str;
  }
  
  function formatDate(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return month + '/' + day + '/' + year;
  }
  function getStartOfQuarter(date) {
    const quarterStartMonth = Math.floor(date.getMonth() / 3) * 3;
    return new Date(date.getFullYear(), quarterStartMonth, 1);
  }
  function getStartOfYear(date) {
    return new Date(date.getFullYear(), 0, 1);
  }
  function add_feature_to_inventory(value, type){
    var id = random_string();
    var new_feature_input = "<input id='" + id + "' " +
      "name='inventory[features][" + type + "][]' type='hidden' " +
      "value='" + value + "'></div>";
    var new_feature_el = "<div style='margin: 5px' class='feature' id='" + id + "'>" +
                  value +
                  ' <a href="" class="fa fa-remove remove_feature"><i class="icon-remove"></i></a>'.
                  concat(new_feature_input);
    var add_to = type == "standard" ? "#standard_features" : "#optional_features";
    $(add_to + ".dropzone").append(new_feature_el);
  }

  $(function(){
    $("#window_sticker_id").change(function () {
      var sticker_id = $(this).find("option:selected").attr('value');
      var path = "print_window_sticker";
      if (sticker_id) window.open(path + "/" + sticker_id + ".pdf", "_blank");
    })
  });

  $(function(){
    $("#export_vehicle_html_id").change(function () {
      var template_id = $(this).find("option:selected").attr('value');
      var path = "export_item_html";
      if (template_id) window.open(path + "?template=" + template_id, "_blank");
    })
  });
  
  $(function(){
    $("#export_vehicle_img_id").change(function () {
      var template_id = $(this).find("option:selected").attr('value');
      var path = "export_item_img";
      if (template_id) window.open(path + "?template=" + template_id, "_blank");
    })
  });

  $("#third_party_exports").hide();
  $(document).on("click", "#show-exports-link", function(e) {
    e.preventDefault();

    var thirdPartyExports = $("#third_party_exports");

    if (thirdPartyExports.is(":visible")) {
      thirdPartyExports.hide();
      $("#show-exports-link").text("Show exports");
    } else {
      thirdPartyExports.show();
      $("#show-exports-link").text("Hide exports");
    }
  });
  $(document).ready(function(){
    $(".update-checkmark").hide();
    $(".update-button-partner").click(function(){
      $(".update-checkmark").toggle();
    });
  });

  $('body').on("click", ".remove_feature", function (event) {
    var $feature, $features, section;

    event.preventDefault();

    $feature = $(event.target).closest(".feature");
    $features = $feature.parent();
    section = $features.attr('id').replace('_features', '');

    $feature.remove();
    if ($features.children().length === 0) $features.html('<input type="hidden" name="inventory[features][' + section + '][]">');
  });
  $("#optional_features.dropzone").sortable({
    items: '.feature',
    connectWith: '#standard_features.dropzone',
    containment: '.features_section',
    receive: function(event, ui){
      var new_id = $(ui.item).attr("id");
      if ($(ui.sender).attr("id") == "common_features"){
        add_feature_to_inventory($(ui.item).text().trim(), "optional");
        $("#" + new_id).remove();
      } else {
        update_feature_details(new_id, 'optional');
      }
    }
  });

  $("#standard_features.dropzone").sortable({
    items: '.feature',
    connectWith: '#optional_features.dropzone',
    containment: '.features_section',
    receive: function(event, ui){
      var new_id = $(ui.item).attr("id");
      if ($(ui.sender).attr("id") == "common_features"){
        add_feature_to_inventory($(ui.item).text().trim(), "standard");
        $("#" + new_id).remove();
      } else {
        update_feature_details(new_id, 'standard');
      }
    }
  });

  function update_feature_details(el, section){
    var old_section = section == "standard" ? "optional" : "standard";
    var item = $("input#" + el + "[type=hidden]");
    var name = item.attr("name");
    var new_section = name.replace(old_section, section);
    item.attr("name", new_section);
  }

  $("#common_features").sortable({
    items: '.feature',
    connectWith: "#optional_features.dropzone, #standard_features.dropzone"
  });

  $(function () {
    function adjustBuyersGuideWarranty() {
      var warrantyCheckbox = $('#buyers_yes'); // Change to the appropriate checkbox ID

      if (warrantyCheckbox.prop('checked')) {
        $('.buyers-guide-warranty').show();
      } else {
        $('.buyers-guide-warranty').hide();
      }
    }
    adjustBuyersGuideWarranty();

    $('input[name="buyers_guide[warranty]"]').change(function() {
      $('input[name="buyers_guide[warranty]"]').not(this).prop('checked', false);
      adjustBuyersGuideWarranty();
    });
    $('input[name="buyers_guide[full_warranty]"]').change(function() {
      $('input[name="buyers_guide[full_warranty]"]').not(this).prop('checked', false);
    });
    $('input[name="buyers_guide[service_contract]"]').change(function() {
      $('input[name="buyers_guide[service_contract]"]').not(this).prop('checked', false);
    });
    $('input[name="buyers_guide[english]"]').change(function() {
      $('input[name="buyers_guide[english]"]').not(this).prop('checked', false);
    });
  });

  $(document).ready(function() {
    $("#post-to-facebook").on("click", function(event) {
      event.preventDefault();
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent($(this).data("url")), "Post to Facebook", "width=640, height=480");
      var vehicleId = $(this).attr('data-vehicle');
      $.ajax({
        url: '/inventory/' + vehicleId + '/export_log_record',
        type: 'POST',
        data: {
          location: "Facebook"
        },
      });
    });
  });

  $(function(){
    $(".buyers_guide_select").change(function () {
      var url = $(this).find("option:selected").val()
      if (url) window.open(url, "_blank");
    })
  });

  function initializePage() {
      const sortDropdown = document.getElementById('sort-dropdown');
      const updateResults = () => {
        const selectedSortValue = sortDropdown.value;

        const redirectUrl = `/api/inventory/filter_sort?sort=${selectedSortValue}`;

        window.location.href = redirectUrl;
      };

      // Listen for change events on both the sort and filter dropdowns
      sortDropdown.addEventListener('change', updateResults);
      filterDropdown.addEventListener('change', updateResults);
    
      const photoOverlay = document.getElementById('photo-overlay');
      if(photoOverlay){

        photoOverlay.addEventListener('click', function (event) {
          event.preventDefault();

          const redirectUrl = `/inventory/photo_overlay`;

          window.location.href = redirectUrl;
        });
      }
    }
  initializePage();

  document.addEventListener("DOMContentLoaded", function () {
    initializePage();
  });
  document.addEventListener("DOMContentLoaded", function() {
      // Add an event listener to the select element
    const importSection = document.getElementById("import_section");
    importSection.addEventListener("change", function() {
      const selectedValue = importSection.value;
      // Hide both options by default
      document.getElementById("cars_for_sale_options").style.display = "none";
      document.getElementById("autotrader_options").style.display = "none";

      // Show the corresponding option based on the selected value
      if (selectedValue === "CarsForSale") {
        document.getElementById("cars_for_sale_options").style.display = "block";
      } else if (selectedValue === "AutoTrader") {
        document.getElementById("autotrader_options").style.display = "block";
      }
    });
  });
  function toggleDropdown(dropdownToggle, dropdownMenu) {
    dropdownToggle.addEventListener("click", function (event) {
      event.preventDefault();
      event.stopPropagation();

      closeAllDropdowns();

      dropdownMenu.classList.toggle("show");
    });
  }

  function closeAllDropdowns() {
    var openDropdowns = document.querySelectorAll(".dropdown-menu.show");
    openDropdowns.forEach(function (openDropdown) {
      openDropdown.classList.remove("show");
    });
  }

  var shareDropdownToggle = document.querySelector("#shareDropdownToggle");
  var shareDropdownMenu = document.querySelector("#shareDropdownToggle + .dropdown-menu");

  var manageDropdownToggle = document.querySelector("#manageDropdownToggle");
  var manageDropdownMenu = document.querySelector("#manageDropdownToggle + .dropdown-menu");

  if (shareDropdownToggle) {
    toggleDropdown(shareDropdownToggle, shareDropdownMenu);
  }

  if (manageDropdownToggle) {
    toggleDropdown(manageDropdownToggle, manageDropdownMenu);
  }

  document.addEventListener("click", function (event) {
    closeAllDropdowns();
  });
  $("form a.add_child").off("click").on("click", function() {
    var targ = $(this);
    targ.addClass("disabled");
    var assoc = targ.attr("data-association");
    var content = $("#" + assoc + "_fields_template").html();
    var regexp = new RegExp("new_" + assoc, "g");
    var new_id = new Date().getTime();
    var $content = $(content.replace(regexp, new_id));

    // Append the new fields to the #fields div
    $("#fields").append($content);

    // Reinitialize any needed plugins or add functionality
    $content.find("select.formula_picker").on("change", function() {
        var selectedValue = $(this).val();
        if (selectedValue === "") {
          var customInput = prompt("Please enter a custom formula:");
          if (customInput) {
              // Add the custom input value to the select
              $(this).append(new Option(customInput, customInput, true, true));
              $(this).val(customInput); // Set the value to the custom input
          }
        }
    });

    // Add a one-second delay before re-enabling the link to ensure proper ordering
    setTimeout(function() {
        targ.removeClass("disabled");
    }, 1000);

    return false; // Prevent the default action of the link
  });

  // Ensure the click event for the "Add" button is only attached once
  $(document).ready(function() {
    // Attach event handler for dynamically added "Add" buttons inside the formula class
    $("#fields").on("click", ".formula button", function(e) {
      e.preventDefault();
      var formulaDiv = $(this).closest(".formula");
      var value = formulaDiv.find("select.formula_picker").val();
      var selected, typed = null;

      if (value) {
        var select = $(this).siblings("select.formula_picker");
        selected = select.find("option[value='" + value + "']");

        // Check if the value has already been added to prevent duplicates
        var alreadyAdded = formulaDiv.find(".output ul li[data-formula='" + value + "']").length > 0;
        if (alreadyAdded) {
            return; // Stop execution if the formula is already added
        }

        // Handle value either as a selected option or as a typed custom input
        if (selected.length === 0) {
            selected = null;
            typed = value; // Set typed to the value from the select
        } else {
            typed = null; // Reset typed as we have a selected option
        }

        var outputList = formulaDiv.find(".output ul");
        var outputItems = outputList.find("li");

        // Add a "+" operation if there are already formula pieces
        if (outputItems.length >= 1) {
            outputList.append(formulaOperation("+"));
        }
        outputList.append(formulaPiece(selected, typed));

        // Update the formula output value
        update_formula_output(formulaDiv);
      }
    });
  });

  function formulaOperation(type) {
    // Type should be either '+' or '-'
    return "<li class='operation label' data-formula='(" + type + ")'>" + type + "</li>";
  }

  function formulaPiece(field, typed) {
    var value, text;
    if (field) {
        value = field.attr("value");
        text = field.text();
    } else {
        value = typed;
        text = typed;
    }
    return "<li class='piece label' data-formula='" + value + "'>" + text + " <i class='fa fa-remove delete-piece'></i></li>";
  }

  function update_formula_output(formulaDiv) {
    cleanup_operations(formulaDiv);
    var input = $(formulaDiv).find(".formula_value");
    var pieces = update_formula_value(formulaDiv);
    input.val(pieces.join(""));
  }

  function update_formula_value(formulaDiv) {
    var pieces = [];
    $(formulaDiv).find(".output li").each(function(i, v) {
        pieces.push($(v).attr("data-formula"));
    });
    return pieces;
  }

  function activate_vin_lookup() {
    $("#inventory_vin").on('input', function (e) {
      var inventory_vin = $("#inventory_vin").val().replace(/\s/g, '') // This will remove white space from string 
      $("#inventory_vin").val(inventory_vin)
      $(".first_notice").hide();
      var new_record = $('#new_record').val();
      if (new_record){ start_lookup(); };
    }); 
    $(".vin_lookup_trigger").click(function (e) {
      $(".first_notice").hide();
      e.preventDefault();
      start_lookup();
    });
    function start_lookup(){
      if ($("#inventory_vin").val().length >= 3 ) {
        $("#decoding").show();
        $(".vin_lookup_trigger").hide();
        $(".vin_decoded").hide();
        var lookup_field, vin, fields, spinner;

        lookup_field = $("#inventory_vin");
        vin = lookup_field.val();

        if (vin == "" || vin == null || vin == "Add a VIN") {
          vin_load_notice("Supply a vin", 2000);
          return false;
        }

        fields = lookup_field.parents(".inventory_fields");

        spinner = $(".spinner");
        spinner.show();

        $.ajax({
          url: "/inventory/" + vin + "/vin_lookup.json",
          dataType: "json",
          success: function (data, status, request) {
            $(".vin_decoded").show();
            $("#decoding").hide();
            $(".vin_lookup_trigger").show();
            var vin_data, fill_fields;
            vin_data = data.inventory;
            console.log(vin_data);

            fill_fields = [
              'year',
              'make',
              'model',
              'trim_level',
              'engine',
              'body_type',
              'vehicle_type',
              'doors',
              'transmission',
              'features',
              'fuel_economy_city',
              'fuel_economy_highway',
              'stock_number'
            ];

            // Basic field value replacement for the items above
            $.each(fill_fields, function (index, value) {
              var section, field_name, field, body_type_field;

              if (vin_data[value] != "" && vin_data[value] != null) {
                section = lookup_field.attr("id").replace("vin", "");

                field_name = "#inventory_" + value;

                field = fields.find(field_name);

                // Checkboxes have to have their checked attr set
                if (value == "features") {
                  // Clear out previously entered features
                  $("#standard_features.dropzone .feature, #optional_features .feature").remove();
                  $.each(vin_data.features.optional, function (i, v) {
                    add_feature_to_inventory(v, "optional");
                  });
                  $.each(vin_data.features.standard, function (i, v) {
                    add_feature_to_inventory(v, "standard");
                  });

                } else if (value == "vehicle_type") {
                  field.val(vin_data[value])
                  body_type_field = "." + "body_type"

                  load_body_type_lookup($(field_name), $(body_type_field), function () {
                    fields.find(body_type_field).val(vin_data["body_type"]);
                  });
                } else {
                  // jQuery takes care of select/text inputs with val
                  field.val(vin_data[value]);
                  $(field).removeClass("inactive");
                }
              }
            });
          }, // end success
          complete: function (request, status) {
            $("#decoding").hide();
            $(".vin_lookup_trigger").show();
            spinner.hide();
          },
          error: function (request, status, error) {
            $(".vin_decoded").hide();
            $("#decoding").hide();
            $(".vin_lookup_trigger").show();
            // 404 or something else
            if ($("#inventory_vin").val().length === 17 ) {
              vin_load_notice("VIN is invalid, try again", 2000);
              error_count = error_count + 1;
              if(error_count >= 1){
                vin_load_notice("Having troubles decoding?", 10000);
              }
            }
            else{
              vin_load_notice("Please enter a 17 digit VIN to decode", 2000);
            }
          }
        }); // end ajax
      }
    } 
      
      
     // end change
  }

  function activate_body_type_lookup() {
    $('#inventory_vehicle_type').on('change', function () {
      load_body_type_lookup($(this), $('#inventory_body_type'));
    });
    $('select.vehicle_type').on('change', function () {
      load_body_type_lookup($(this), $('select.body_type'));
    });
  }

  function load_body_type_lookup($vehicleTypeSelect, $bodyTypeSelect, callback) {
    var vehicleType = $vehicleTypeSelect.val();
    $.get('/inventory/body_type_lookup?vehicle_type=' + vehicleType, function (data) {
      $bodyTypeSelect.find('option').remove();
      $bodyTypeSelect.append('<option value=""></option>');
      $.each(data, function (index, value) {
        $bodyTypeSelect.append('<option value="' + value + '">' + value + '</option>');
      });
      if (callback) {
        callback();
      }
    });
  }

  function vin_load_notice(message, delay){
    $(".load_notice").html(message).show().delay(delay).fadeOut(2000);
  }

  function toggle_print_forms_button() {
    if ($(".printable_form:checked").length > 0) {
      $("#print_form_button").removeAttr("disabled");
      $("#email_form_button").removeAttr("disabled");
    } else {
      $("#print_form_button").attr("disabled", "disabled");
      $("#email_form_button").attr("disabled", "disabled");
    }
  }

  function calculate_additional_inventory_costs(){
    total = 0;
    setup_inventory_costs_fields();
    $.each($(".inventory_cost input[title=Amount]"), function(index, value){
      total += $(this).asNumber();
    });

    if ($("#floorplan_cost").length > 0) {
      total += $("#floorplan_cost").asNumber()
    }

    $("#cost").attr('value', total).formatCurrency();
  }

  function setup_inventory_costs_fields(){
    $(".inventory_cost input").change(function(){
      calculate_additional_inventory_costs();
    });

    $("form:has(input[title=Amount])").bind("submit", function(event){
      $(this).find("input[title=Amount]").each(function(){
        $(this).toNumber();
      });
    });
  }
  // helper function to generate slugs
  function to_slug(text){
    return text.replace(/\s+/g,'-').replace(/[^a-zA-Z0-9\-]/g,'').toLowerCase();
  }

  function activate_currency_fields(sel){
    $(".currency_decimal").formatCurrency();
    $(".currency_decimal").blur(function(){
      $(this).formatCurrency();
    });
    $(".currency, .currency_decimal").focus(function(){
      $(this).removeClass('inactive');
      $(this).toNumber();
    });
  }

  function get_authenticity_token(){
    return ($('input[name="authenticity_token"]').first().val() || $('meta[name="csrf-token"]').attr('content'));
  }

  function get_url_param_by_name(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  function setup_tablesorter() {
    return $('table.tablesorter').tablesorter({
      theme: 'bootstrap',
      widthFixed: true,
      headerTemplate: '{content} {icon}',
      widgets: ['uitheme', 'filter', 'zebra'],
      widgetOptions: {
        zebra: ['even', 'odd']
      }
    });
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  function random_id() {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
  }  

  function cleanup_operations(formulaDiv) {
    var outputDivs = formulaDiv.find(".output li");

    // Remove any stray operation symbols at the start or end
    if (outputDivs.first().hasClass("operation")) {
        outputDivs.first().remove();
    } else if (outputDivs.last().hasClass("operation")) {
        outputDivs.last().remove();
    }

    var last = null;
    $.each(outputDivs, function(i, v) {
        var j = $(v);
        if (last && last.hasClass("operation") && j.hasClass("operation")) {
            last.remove();
        }
        last = j;
    });
  }
  $(".formula .output").on("click", ".delete-piece", function(e) {
    e.preventDefault();
    var formulaDiv = $(this).closest(".formula");
    $(this).closest("li").remove();
    update_formula_output(formulaDiv);
  });
  $(document).ready(function() {
    $("#layout .form_field[id!=form_image]").draggable({
      grid: [1, 1],
      snap: ".draggable-y, .draggable-x",
      snapTolerance: 5,
      containment: "#layout",
      zIndex: 5000,
      cursor: "pointer",
      scroll: true,
      stop: function(event, ui) {
        var element = $(event.target);
        var id = element.attr("id").replace("form_field_", "");
        var jElement = $(element);
        $("#field_print_x_" + id).attr("value", jElement.css("left"));
        $("#field_print_y_" + id).attr("value", jElement.css("top"));
      }
    });

    $(".print-widths").val(function() {
      return $(this).nextAll(".ui-draggable").first().width();
    });

    $("#fields").on("click", ".formula button", function(e) {
      e.preventDefault();
      var formulaDiv = $(this).parents(".formula");
      var value = $(this).siblings(".combobox-container").find("[name='formula_picker']").val();
      
      if (value) {
        var select = $(this).siblings("select.formula_picker");
        var selected = select.find("option[value='" + value + "']");
        
        if (selected.length > 0) {
          var typed = null;
        } else {
          var typed = value;
          select.find("option").each(function() {
            var optionText = $(this).text().replace(/\s/g, "").toLowerCase();
            var valueText = value.replace(/\s/g, "").toLowerCase();
            if (valueText === optionText) {
              selected = $(this);
              typed = null; // If a match is found, set typed to null
              return false; // Break the loop
            }
          });
        }

        $(this).siblings(".combobox-container").find(".combobox-clear").click();

        var outputList = formulaDiv.find(".output ul");
        var outputItems = outputList.find("li");

        if (outputItems.length >= 1) {
          outputList.append(formula_operation("+"));
        }
        outputList.append(formula_piece(selected, typed));

        update_formula_output(formulaDiv);
      }
    });
  });
})
document.addEventListener('DOMContentLoaded', function() {
  const dropdown = document.getElementById('account-dropdown');
  
  // Add event listener for dropdown changes
  dropdown.addEventListener('change', function() {
    const selectedValue = this.value;
    
    // Redirect if the value is not "other"
    if (selectedValue && selectedValue !== "other") {
      window.location.href = selectedValue;
    }
  });
});

$('#billing-dropdown').change(function() {
  var selectedValue = $(this).val();
  if (selectedValue) {
    window.location.href = selectedValue;
  }
});
$(document).on("scroll", function () {
  const sidebar = $("#sidenav-main");
  const scrollPosition = $(window).scrollTop();

  if (scrollPosition > 30) {
      sidebar.css("top", "0px");
  } else {
      sidebar.css("top", "90px");
  }
});

$(document).on('click', '.date-input', function() {
  console.log('hi');
  $('.date-input').off('input').on('input', function () {
    const $dateField = $(this);
    const $errorMessage = $dateField.next('.date-error');

    let value = $dateField.val().replace(/\D/g, '');

    if (value.length > 2) {
        value = value.slice(0, 2) + '/' + value.slice(2);
    }
    if (value.length > 5) {
        value = value.slice(0, 5) + '/' + value.slice(5, 9);
    }
    $dateField.val(value);

    // Validate input as the user types
    validateAndUpdate(value, $dateField, $errorMessage);
  });

  $('.date-input').on('blur', function () {
    const $dateField = $(this);
    const $errorMessage = $dateField.next('.date-error');
    const value = $dateField.val();

    // Validate input on blur (losing focus)
    validateAndUpdate(value, $dateField, $errorMessage);
  });

  function validateAndUpdate(value, $dateField, $errorMessage) {
    let isValid = true;
    let errorMessage = '';

    // Identify the field type
    const isDateOfBirth = $dateField.hasClass('date-of-birth');
    const isPastDateNotAllowed = !$dateField.hasClass('date-of-birth');

    // Validate empty value
    if (value.length === 0) {
        isValid = false;
        errorMessage = 'Please enter a valid date in mm/dd/yyyy format.';
    }

    // Validate Month
    if (isValid && value.length >= 2) {
        const month = parseInt(value.slice(0, 2), 10);
        if (isNaN(month) || month < 1 || month > 12) {
            isValid = false;
            errorMessage = 'Invalid month. Please enter a valid month (01-12).';
        }
    }

    // Validate Incomplete Date (only after month validation)
    if (isValid && value.length < 10) {
        isValid = false;
        errorMessage = 'Incomplete date. Please enter a valid date in mm/dd/yyyy format.';
    }

    // Validate Day
    if (isValid && value.length >= 5) {
        const month = parseInt(value.slice(0, 2), 10);
        const day = parseInt(value.slice(3, 5), 10);
        if (isNaN(day) || day < 1 || day > 31) {
            isValid = false;
            errorMessage = 'Invalid day. Please enter a valid day.';
        } else {
            const daysInMonth = [
                31, (isLeapYear(new Date().getFullYear()) ? 29 : 28),
                31, 30, 31, 30, 31, 31, 30, 31, 30, 31
            ];
            if (day > daysInMonth[month - 1]) {
                isValid = false;
                errorMessage = 'Invalid day for the selected month.';
            }
        }
    }

    // Validate Year and Complete Date
    if (isValid && value.length === 10) {
        const [month, day, year] = value.split('/').map(Number);
        if (!validateDate(month, day, year)) {
            isValid = false;
            errorMessage = 'Invalid date. Please enter a valid date in mm/dd/yyyy format.';
        }

        // Custom validation for Date of Birth (not in the future)
        if (isDateOfBirth && isValid) {
            const inputDate = new Date(year, month - 1, day);
            const today = new Date();
            if (inputDate > today) {
                isValid = false;
                errorMessage = 'Date of birth cannot be in the future.';
            }
        }

        // Custom validation for future dates (payment, expiration, etc.)
        if (isPastDateNotAllowed && isValid) {
            const inputDate = new Date(year, month - 1, day);
            const today = new Date();
            if (inputDate < today) {
                isValid = false;
                errorMessage = 'This date cannot be in the past.';
            }
        }
    }

    // Update UI based on validation result
    if (!isValid) {
        $errorMessage.text(errorMessage);
        $dateField.addClass('is-invalid');
    } else {
        $errorMessage.text('');
        $dateField.removeClass('is-invalid');
    }
  }

  function validateDate(month, day, year) {
    if (month < 1 || month > 12 || day < 1 || day > 31) return false;

    const daysInMonth = [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (day > daysInMonth[month - 1]) return false;

    return true;
  }

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }
});
