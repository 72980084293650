<template lang="pug">
tr
  td
    a(:href="'/deals/' + deal.id + '/edit'")
      h6(class="ms-3 my-auto deal_nm d-flex align-items-center")
        .word-wrapper
          | {{  deal.deal_name }}
  td
    span(class="badge bg-success ms-3 my-auto mt-1" v-if="deal.bhph_deal") bhph

  td(class="text-sm d_date") {{ deal.close_date }}
  td(class="text-sm text-center icon-container")

    a(:href="'/deals/' + deal.id" class="me-3")
      span(class="badge d_overview") PRINT FORMS

    a(v-if="deal.deal_closed" :href="'/deals/' + deal.id + '/open'" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Undo close" @click.stop.prevent="openDeal")
      span(class="badge badge-secondary") Undo close
      
    a(v-else :href="'/deals/' + deal.id + '/close'" class="me-3" data-method="post" data-bs-toggle="tooltip" data-bs-original-title="Sold? Close" @click.stop.prevent="closeDeal")
      span(class="badge sold_close") sold? close
    
    a(href="javascript:;" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Delete" @click.stop.prevent="remove")
      i(class="fas fa-trash text-secondary" title="Delete")
    
    a(v-if="deal.active" href="#" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="archive" 
    @click.stop.prevent="archive")
      i(class="fas fa-arrow-down text-secondary" title="Archive")
    
    a(v-else href="#" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Unarchive" @click.stop.prevent="unarchive")
      i(class="fas fa-undo text-secondary" title="Unarchive")

</template>

<script>
import archiveData from "../../hooks/archiveDeleteData";
import closeDealData from "../../hooks/closeDealData";
import openDealData from "../../hooks/openDealData";

export default {
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { archive, unarchive, remove } = archiveData(
      "/api/deals/",
      props.deal.id,
      emit
    );
    const { closeDeal } = closeDealData("/deals/", props.deal.id, emit);
    const { openDeal } = openDealData("/deals/", props.deal.id, emit);

    return { archive, unarchive, remove, closeDeal, openDeal };
  },
};
</script>

<style lang="sass" scoped>
.icon-container
  padding-left: 22px

.d_date
  padding-left: 22px

.deal_nm
  font-size: 0.8em !important

.sold_close
  color: #f8fffc
  background-color: #12c376

.d_overview
  color: #f8fffc
  background-color: #596cff

.word-wrapper
  display: block
  white-space: normal
  word-break: break-word
  min-width: 200px
  text-align: left

table tr td:nth-child(4)
  text-align: left !important
</style>
