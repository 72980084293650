import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inventorySwitch", "leadSwitch", "dealSwitch", "accountingSwitch", "websiteSwitch", "financialSwitch", "reportSwitch", "adminSwitch", "spinner", "actions"]

  initialize() {
    this.user_id = Number(this.element.dataset.user_id)
    this.adminCheck()
  }

  // When the switch is toggled:
  // 1. Enable the loading state
  // 2. Send a PATCH request to the server
  // 3. If the request is successful:
  //    - Apply checkbox states along with the response
  //    - Disable the loading state
  //    - Make an admin check: if the admin switch is toggled on, disable the other switches and vice versa
  // 4. If the request is unsuccessful:
  //    - Revert the checkbox state
  //    - Disable the loading state
  //    - Show an error message
  toggleSwitch(event) {
    // save previous state
    const previousState = !event.target.checked

    this.setLoadingState(true)
    this.sendUpdateUserPermissionRequest()
      .then((response) => {
        this.applyCheckboxStates(response)
        this.setLoadingState(false)
        this.adminCheck()
      })
      .catch((error) => {
        event.target.checked = previousState
        this.setLoadingState(false)
        this.errorMessage(error)
      })
  }

  applyCheckboxStates(response) {
    this.inventorySwitchTarget.checked = response.inventory
    this.leadSwitchTarget.checked = response.leads
    this.dealSwitchTarget.checked = response.deals
    this.accountingSwitchTarget.checked = response.accounting
    this.websiteSwitchTarget.checked = response.website
    this.financialSwitchTarget.checked = response.financial_data
    this.reportSwitchTarget.checked = response.reports
    this.adminSwitchTarget.checked = response.admin
  }

  // When loading:
  // - Disable all checkboxes
  // - Show spinner
  // And vice versa
  setLoadingState(state) {
    if (state) {
      this.inventorySwitchTarget.disabled = true
      this.leadSwitchTarget.disabled = true
      this.dealSwitchTarget.disabled = true
      this.accountingSwitchTarget.disabled = true
      this.websiteSwitchTarget.disabled = true
      this.financialSwitchTarget.disabled = true
      this.reportSwitchTarget.disabled = true
      this.adminSwitchTarget.disabled = true
      this.spinnerTarget.classList.remove("hidden")
      this.actionsTarget.classList.add("hidden")
    } else {
      this.inventorySwitchTarget.disabled = false
      this.leadSwitchTarget.disabled = false
      this.dealSwitchTarget.disabled = false
      this.accountingSwitchTarget.disabled = false
      this.websiteSwitchTarget.disabled = false
      this.financialSwitchTarget.disabled = false
      this.reportSwitchTarget.disabled = false
      this.adminSwitchTarget.disabled = false
      this.spinnerTarget.classList.add("hidden")
      this.actionsTarget.classList.remove("hidden")
    }
  }

  adminCheck() {
    if (this.adminSwitchTarget.checked) {
      this.inventorySwitchTarget.disabled = true
      this.leadSwitchTarget.disabled = true
      this.dealSwitchTarget.disabled = true
      this.accountingSwitchTarget.disabled = true
      this.websiteSwitchTarget.disabled = true
      this.financialSwitchTarget.disabled = true
      this.reportSwitchTarget.disabled = true
    } else {
      this.inventorySwitchTarget.disabled = false
      this.leadSwitchTarget.disabled = false
      this.dealSwitchTarget.disabled = false
      this.accountingSwitchTarget.disabled = false
      this.websiteSwitchTarget.disabled = false
      this.financialSwitchTarget.disabled = false
      this.reportSwitchTarget.disabled = false
    }
  }

  // Send a PATCH request to the server
    sendUpdateUserPermissionRequest() {
    const body = {
      inventory: this.inventorySwitchTarget.checked,
      leads: this.leadSwitchTarget.checked,
      deals: this.dealSwitchTarget.checked,
      accounting: this.accountingSwitchTarget.checked,
      website: this.websiteSwitchTarget.checked,
      financial_data: this.financialSwitchTarget.checked,
      reports: this.reportSwitchTarget.checked,
      admin: this.adminSwitchTarget.checked
    }

    return fetch(`/api/users/${this.user_id}/permissions`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json())
  }


}
